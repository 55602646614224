import React, { useState, useEffect } from 'react';
import DraggableCard from './DraggableCard';
import './App.css';
import decksData from './cards.json';
import useViewportHeight from './hooks/useViewportHeight';

function App() {
  useViewportHeight();

  const [deck, setDeck] = useState('deck1');
  const [cards, setCards] = useState(decksData[deck]);
  const [currentCardIndex, setCurrentCardIndex] = useState(cards[0].id);

  useEffect(() => {
    const preloadImages = () => {
      decksData[deck].forEach(card => {
        const img = new Image();
        img.src = card.image;
      });
    };

    setCards(decksData[deck]);
    setCurrentCardIndex(decksData[deck][0].id);

    preloadImages();
  }, [deck]);

  const handleCardChange = (nextCardId) => {
    setCurrentCardIndex(nextCardId);
  };

  const handleNextDeck = () => {
    const deckNames = Object.keys(decksData);
    const currentDeckIndex = deckNames.indexOf(deck);
    const nextDeckIndex = (currentDeckIndex + 1) % deckNames.length;
    const nextDeck = deckNames[nextDeckIndex];
    setDeck(nextDeck);
  };

  const currentDeck = () => {
    const deckNames = Object.keys(decksData);
    const currentDeckIndex = deckNames.indexOf(deck);
    return currentDeckIndex + 1;
  }

  const currentCard = cards.find(card => card.id === currentCardIndex);

  return (
    <div className="App">
      <div className="container">
        <header>
          <div className='noise'>
            <div className='noise-bg'></div>
            <div className='plain'>
              <img src="/assets/logo_plus_bookmate.png" alt="Yandex Plus Bookmate" className="logo-ya-bm" />
            </div>
            <div className='circles'>
              <img src="/assets/logo_title.png" alt="Бояться поздно" className="logo-title" />
            </div>
          </div>
        </header>
        <div className="content">
          {currentCard && <p>{currentCard.description}</p>}
          <div className="wrapper">
          {currentCard && (
            <DraggableCard
              cards={cards}
              currentCardIndex={currentCardIndex}
              onCardChange={handleCardChange}
              handleNextDeck={handleNextDeck}
              backSideImage="assets/card_back.png" />
          )}
          </div>
        </div>
        <footer>
          <div className="footer-element">
            <svg viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M49.9713 3.52738C46.0551 -0.305698 40.0909 -0.972411 35.4252 1.51574C34.4768 2.01944 33.5835 2.65427 32.773 3.42444C32.7179 3.46729 32.6623 3.52163 32.6126 3.57022L32.5463 3.63554L27.037 9.02567L23.8966 12.0948L18.3879 17.4897C18.3713 17.5059 18.3601 17.5163 18.3488 17.533L18.3429 17.5377C16.8727 18.9485 14.9363 19.691 12.862 19.7067C10.7647 19.702 8.78976 18.8936 7.29709 17.4353C4.22354 14.431 4.19628 9.56698 7.24149 6.59133C10.2707 3.63083 15.225 3.64128 18.298 6.60701C18.3039 6.61798 18.3151 6.62895 18.3258 6.63992L22.7253 10.9448L25.8592 7.87041L21.4935 3.59791L21.4651 3.57074C21.4095 3.5164 21.3545 3.46781 21.2989 3.42497C16.4905 -1.11349 8.82344 -1.08632 4.10219 3.52738C-0.668793 8.19019 -0.646874 15.8088 4.15779 20.504C6.48233 22.7758 9.56711 24.034 12.8454 24.0439L12.8957 24.0439C16.1355 24.0439 19.1759 22.8296 21.4667 20.6122C21.4892 20.5965 21.5111 20.5798 21.5336 20.5583L22.1826 19.923L27.037 15.1682L30.1774 12.0938L35.7423 6.64462L35.7481 6.6394C36.4416 5.96694 37.2237 5.44078 38.0615 5.07189C40.957 3.78183 44.4796 4.28657 46.8378 6.59081C49.8777 9.56698 49.8552 14.4304 46.7822 17.4348C43.7755 20.3791 38.9436 20.4653 35.8807 17.6778L31.3535 13.2485L28.2137 16.3167L32.5463 20.5578C34.9259 22.8777 38.0497 24.0387 41.1831 24.0387C44.3406 24.0387 47.5077 22.861 49.9162 20.5029C54.7262 15.8088 54.7487 8.19071 49.9713 3.52738Z" fill="#1A282F"/>
            </svg>
          </div>
          <div className="footer-element">
            <h2>
             {currentDeck()}
            </h2>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
