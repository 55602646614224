import React, { useState, useEffect, useRef } from 'react';
import { DraggableCore } from 'react-draggable';
import './DraggableCard.css';

const DraggableCard = ({ cards, currentCardIndex, onCardChange, handleNextDeck, backSideImage }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const [tutorialActive, setTutorialActive] = useState(true);
  const [animationClass, setAnimationClass] = useState('');
  const [flipClass, setFlipClass] = useState('');
  const [nextCard, setNextCard] = useState(null);
  const targetPositionRef = useRef({ x: 0, y: 0 });
  const timeoutsRef = useRef([]);
  const draggableRef = useRef(null);

  const currentCard = cards.find(card => card.id === currentCardIndex);
  const nextCardData = nextCard !== null ? cards.find(card => card.id === nextCard) : null;

  const animationDurationX = 0.5;
  const animationDurationZ = 0.15;
  const epsilon = 0.01;

  const clearAllTimeouts = () => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
  };

  useEffect(() => {
    if (!tutorialActive) return;
    if (currentCardIndex > 1) return;

    const runTutorialAnimation = () => {
      setAnimationClass('animate-left');
      targetPositionRef.current = { x: -80, y: 0 };
      const timeout1 = setTimeout(() => {
        setAnimationClass('animate-right');
        targetPositionRef.current = { x: 80, y: 0 };
        const timeout2 = setTimeout(() => {
          setAnimationClass('animate-center');
          targetPositionRef.current = { x: 0, y: 0 };
          const timeout3 = setTimeout(runTutorialAnimation, 5000);
          timeoutsRef.current.push(timeout3);
        }, 1000);
        timeoutsRef.current.push(timeout2);
      }, 1000);
      timeoutsRef.current.push(timeout1);
    };

    const startTimeout = setTimeout(runTutorialAnimation, 1500);
    timeoutsRef.current.push(startTimeout);

    return () => {
      clearAllTimeouts();
      setAnimationClass('');
    };
  }, [currentCardIndex, tutorialActive]);

  useEffect(() => {
    if (!currentCard || currentCard.type !== 'simple') return;

    let animationFrameId;
    const animate = () => {
      setPosition(prevPosition => {
        const targetPosition = targetPositionRef.current;
        const dx = targetPosition.x - prevPosition.x;
        if (Math.abs(dx) < epsilon) return prevPosition;
        const newX = prevPosition.x + dx * animationDurationX;
        const newRotateY = newX / 10;
        setRotateY(newRotateY);
        return { x: newX, y: 0 };
      });

      setRotateZ(prevRotateZ => {
        const targetRotateZ = position.x / 20;
        const dz = targetRotateZ - prevRotateZ;
        if (Math.abs(dz) < epsilon) return prevRotateZ;
        return prevRotateZ + dz * animationDurationZ;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [position.x, animationDurationX, animationDurationZ, currentCard]);

  const handleDrag = (e, data) => {
    if (!currentCard || currentCard.type !== 'simple') return;
    clearAllTimeouts();
    setAnimationClass('');
    setTutorialActive(false);
    const newX = Math.min(100, Math.max(-100, targetPositionRef.current.x + data.deltaX));
    targetPositionRef.current = { x: newX, y: 0 };
  };

  const handleStop = () => {
    if (!currentCard || currentCard.type !== 'simple') return;
    if (Math.abs(targetPositionRef.current.x) >= 50) {
      setIsHidden(true);
      setTimeout(() => {
        const nextCardId = position.x > 0 ? currentCard.rightAnswer.nextCardId : currentCard.leftAnswer.nextCardId;
        setNextCard(nextCardId);
        setFlipClass(`flip-${position.x > 0 ? 'left' : 'right'}`);
        setTimeout(() => {
          onCardChange(nextCardId);
          setFlipClass('');
          setPosition({ x: 0, y: 0 });
          setRotateY(0);
          setRotateZ(0);
          targetPositionRef.current = { x: 0, y: 0 };
          setIsHidden(false);
        }, 250);
      }, 500);
    } else {
      targetPositionRef.current = { x: 0, y: 0 };
      setPosition({ x: 0, y: 0 });
      setRotateY(0);
      setRotateZ(0);
    }
  };

  return (
    <>
      <div className={`static-card ${flipClass}`} style={{ backgroundImage: `url(${backSideImage})` }}></div> {/* Рубашка карты */}
      {nextCardData && flipClass && (
        <div className={`static-card next-card`} style={{ backgroundImage: `url(${nextCardData.image})` }}></div>
      )}
      {currentCard && currentCard.type === 'simple' ? (
        <DraggableCore nodeRef={draggableRef} onDrag={handleDrag} onStop={handleStop}>
          <div
            ref={draggableRef}
            className={`card-wrapper ${animationClass} ${isHidden ? 'hidden' : ''}`}
            style={{
              transform: `translateX(${isHidden ? (position.x > 0 ? 20 : -20) : position.x}px) rotateY(${isHidden ? 0 : rotateY}deg) rotateZ(${isHidden ? 0 : rotateZ}deg) ${isHidden ? 'translateY(500px)' : ''}`,
              opacity: isHidden ? 0 : 1,
            }}
          >
            <div className="card" style={{ backgroundImage: `url(${currentCard.image})` }}>
              <div
                className="overlay"
                style={{
                  opacity: Math.abs(position.x) / 100 - 0.05,
                  background: position.x > 0 ? '#13191F' : '#13191F',
                  justifyContent: position.x > 0 ? 'flex-start' : 'flex-end',
                  transform: `rotate(${-rotateZ}deg)`
                }}
              >
                <div
                  className='text-answer'
                  style={{
                    textAlign: `${position.x > 0 ? 'left' : 'right'}`,
                    opacity: 1,
                    color: `${position.x > 0 ? '#A743D4' : '#F34F7D'}`,
                  }}>
                  {position.x > 0 ? currentCard.rightAnswer.text : currentCard.leftAnswer.text}
                </div>
              </div>
            </div>
          </div>
        </DraggableCore>
      ) : (
        currentCard && (
          <div className={`card-wrapper`}>
            <div className={`card ${currentCard.type}`} style={{
                backgroundImage: `url(${currentCard.image})`,
                borderColor: currentCard.type === 'red' ? '#AD0522' : '#0E8049',
                borderWidth: currentCard.type === 'red' ? '3px' : '3px',
                cursor: 'default',
              }}>
              {currentCard.type === 'red' && (
                <>
                  <div className="card-blur" style={{
                    backgroundImage: `url(${currentCard.image})`,
                  }}></div>
                  <div className="card-darkness"></div>
                  <div className="red-svg"></div>
                  <div className="red-buttons">

                    <div className='effects-wrapper-red'>
                      <div className="button-glow"></div>
                      <div className="button-flickers"></div>
                      <div className="button-wrapper">
                          <div className="button-border"></div>
                          <a href="https://bookmate.ru/audiobooks/vX4wd0nl" target="_blank" rel="noreferrer" className="button-with-illustration">
                            <h4>Перейти к книге</h4>
                          </a>
                          <div className="button-illustration"></div>
                      </div>
                    </div>

                    <div className="button-wrapper">
                      <div className="button-border-gray"></div>
                      <button className="button-without-illustration" onClick={handleNextDeck}>
                        <h4>Поиграть ещё</h4>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {currentCard.type === 'green' && (
                <>
                  <div className="card-blur" style={{
                    backgroundImage: `url(${currentCard.image})`,
                  }}></div>
                  <div className="card-darkness"></div>
                  <div className="button-glow"></div>
                  <div className="button-flickers"></div>
                  <div className="green-svg"></div>
                  <div className="button-wrapper">
                      <div className="button-border"></div>
                      <a href="https://bookmate.ru/audiobooks/vX4wd0nl" target="_blank" rel="noreferrer" className="button-with-illustration">
                        <h4>Перейти к книге</h4>
                      </a>
                      <div className="button-illustration"></div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default DraggableCard;
